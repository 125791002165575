import { useStaticQuery, graphql } from "gatsby";
import { MetaDataQueryQuery } from "../../gatsby-graphql";

export const useMetaData = () => {
    const data = useStaticQuery<MetaDataQueryQuery>(graphql`
        query MetaDataQuery {
            allSite {
                edges {
                  node {
                    siteMetadata {
                      title
                      siteUrl
                      description
                      author
                    }
                  }
                }
              }
        }`
    )

    return data.allSite.edges[0].node.siteMetadata;
}