import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { WpNodeWithTitle, Scalars, Maybe } from "../../gatsby-graphql";
import { useMetaData } from "../hooks/metadata.hook";

export interface ISEOProps extends WpNodeWithTitle {
  description?: Maybe<Scalars['String']>,
  lang?: string,
  // will want to revist
  // used to concat meta to combine
  meta?: object[],
  image?: string | null,
  // Need to implement
  keywords?: string[]
}


export const SEO: React.FC<ISEOProps> = props => {
  const metaData = useMetaData();

  const siteTitle = metaData?.title;
  const title = props.title || siteTitle || '';
  const metaDescription = props.description || metaData?.description || '';

  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang || 'en',
      }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: props.image || '',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // Want to get correct id for this
        // {
        //   property: `fb:id`,
        // },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'theme-color',
          content: '#FFFFFF',
          media: '(prefers-color-scheme: light)',
        },
        {
          name: 'theme-color',
          content: '#000000',
          media: '(prefers-color-scheme: light)',
        }
      ]}
    />
  )
}

export default SEO
